<template>
  <div class="hotelPackage">
    <div class="choose">
      <el-menu
        router
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        active-text-color="#256DEF"
      >
        <el-menu-item index="/promotionManagement">携程促销管理</el-menu-item>
         <el-menu-item index="/optimalWill">携程优享会</el-menu-item>
         <el-menu-item index="/promotionManagementYiLong">艺龙促销管理</el-menu-item>
         <el-menu-item index="/optimalWillYiLong">艺龙优享会</el-menu-item>
         <el-menu-item index="/stencilYiLong">艺龙模板</el-menu-item>
        <!-- <el-menu-item index="/enrollment">活动报名</el-menu-item>
        <el-menu-item index="/rightsCenter">权益中心</el-menu-item> -->
       
      </el-menu>
    </div>
    <!-- <PromotionManagement v-if="index==1"></PromotionManagement> -->
     <router-view></router-view>
  </div>
</template>

<script>
//import PromotionManagement from "./PromotionManagement.vue";
export default {
    // components:{
    //  PromotionManagement,
    // },
  data() {
    return {
      activeIndex: "/promotionManagement", //管理分类
      index: "1", //管理分类与组件对应展示的方式
    };
  },
   mounted() {
            this.activeIndex = this.$route.path
        },

  methods:{
       handleSelect(key, keyPath) {
    //  console.log(key, keyPath);
     // this.index = key;
    },
  }
};
</script>

<style lang="less" scoped>
.choose {
    width: 100%;
    height: 40px;
    background: #fff;
    margin-bottom: 20px;
    .el-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
     
    }
  }
  .el-menu--horizontal>.el-menu-item{
      height: 40px;
      line-height: 40px;
  }
</style>